<template>
  <modal-padrao
    ref="modal-etiquetas"
    :max-width="EhTelaPequena ? '90%' : '50%'"
    :titulo="$t('modulos.operacao.botoes.etiquetas_certificados')"
    :mostrar-botoes="false"
  >
    <div style="min-height: 200px">
      <div class="d-flex align-center justify-end">
        <botao-padrao
          :disabled="!acreditacaoSelecionadaId"
          :tooltip="!acreditacaoSelecionadaId ? $t('modulos.operacao.tooltips.selecionar_acreditacao') : ''"
          @click="gerarEtiquetaStimulsoft"
        >
          {{ $t('modulos.operacao.botoes.imprimir_etiqueta') }}
        </botao-padrao>
      </div>
      <tabela-padrao-prime-vue
        v-model="selecionados"
        :dados="tabela.dados.filter(el => el.acreditacoes.some(a => a.id === acreditacaoSelecionadaId))"
        filtro-geral
        class="mt-2"
        :mostrar-seletor="false"
        :colunas="tabela.colunas"
        paginacao-em-memoria
        sem-paginacao
        :por-pagina="10"
        :filters="filters"
        mostrar-select-filtro
        :placeholder-select-filtro="'Acreditação'"
        :opcoes-select="acreditacoes"
        selecionar-automatico-select
        ajustar-altura-linha
        :mostrar-acoes="false"
        :global-filters="tabela.colunas.map(c => c.value)"  
        @filtroSelect="selecionarAcreditacao"
      />
    </div>
    <modal-visualizacao-relatorio ref="modal-pdf" />
  </modal-padrao>
</template>
  <script>
  import { FilterMatchMode } from 'primevue/api';
  import { ModalVisualizacaoRelatorio } from '@components/misc';
  import _ from 'lodash';
  
  export default {
    components: {
      ModalVisualizacaoRelatorio,
    },
    props: {
      ordemServicoId: { type: String, default: null },
    },
    data() {
      return {
        selecionados: [],
        acreditacoes: [],
        acreditacaoSelecionadaId: null,
        tabela: {
          dados: [],
          colunas: [
            {
            value: 'instrumento.codigo',
            text: this.$t('modulos.operacao.tabela.instrumento'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.operacao.tabela.tipo_instrumento'),
            sortable: true,
          },         {
            value: 'certificado',
            text: this.$t('modulos.operacao.tabela.certificado'),
            sortable: true,
          },
          {
            value: 'tipoCalibracao.nome',
            text: this.$t('modulos.operacao.tabela.tipo_calibracao'),
            sortable: true,
          },
          ],
        },
        filters: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      };
    },
    computed: {
      EhTelaPequena() {
        return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
      },
    },
    methods: {
      selecionarAcreditacao(acreditacao){
        this.acreditacaoSelecionadaId = acreditacao?.code;
      },
      montarDropdownAcreditacoes(acreditacoes) {
        this.acreditacoes = acreditacoes?.map(el => {
            return { name: el.codigo, code: el.id }
        })
      },
      abrirModal: function (instrumentos) {
        const acreditacoes = [];
        instrumentos.forEach(i => i.acreditacoes?.forEach(a => acreditacoes.push(a)));
        this.montarDropdownAcreditacoes(_.uniqBy(acreditacoes, 'id'))

        this.tabela.dados = instrumentos;
        this.$refs['modal-etiquetas'].abrirModal();
      },
      gerarEtiquetaStimulsoft(){
        this.$refs['modal-pdf'].abrirModal('etiquetas', [
        { simbolo: 'ordemServicoId', valor: this.ordemServicoId },
        { simbolo: 'acreditacaoId', valor: this.acreditacaoSelecionadaId },
      ]);
      },
    },
  };
  </script>
  
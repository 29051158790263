<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.operacao.titulos.plano_inspecao')"
      :total-registros="tabela.quantidadeItens"
      :sem-filtro="true"
      :sem-botoes="true"
      :sem-botao-ok="true"
      :sem-registros="true"
    />
    <div>
      <v-chip
        class="mr-1"
        small
      >
        Ordem de Serviço: {{ `${codigoOS}` }}
      </v-chip>
    </div>
    <div class="mt-2">
      {{ $t('modulos.operacao.selecione_servicos_pecas') }}
    </div>

    <v-form
      ref="form"
      class="row mt-2 mb-4"
    >
      <input-text
        v-model="cliente"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.cliente')"
        disabled
      />
      <input-text
        v-model="solicitante"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.solicitante')"
        disabled
      />

      <input-date
        v-model="dataRecebimento"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.data_recebimento_os')"
        disabled
      />

      <input-date
        v-model="dataPrevisaoEntrega"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.data_previsao_entrega_os')"
        disabled
      />

      <input-text
        v-model="embalagemRecebimento"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.embalagem_recebimento')"
        disabled
      />
      <input-select
        v-model="form.embalagemEntregaId"
        :options="opcoes.embalagens"
        :loading="loading.embalagensLoading"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.embalagem_entrega')"
        clearable
      />
      <input-text
        v-model="form.quantidadeVolumes"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.qtd_volumes')"
        type="number"
      />
      <input-text
        v-model="form.peso"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.peso_kg')"
        type="number"
      />
    </v-form>
    <div class="d-flex align-center justify-end">
      <botao-padrao
        @click="abreModalEtiquetas"
      >
        {{ $t('modulos.operacao.botoes.etiquetas_certificados') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :mostra-header="false"
      :dados="tabela.dados"
      :mostrar-acoes="false"
      :selecionar-colunas="false"
      :sem-paginacao="true"
      :mostrar-seletor="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      ajustar-altura-linha
    >
      <template
        v-if="tabela.dados?.length"
        v-slot:okNaoOk="{slotProps}"
      >
        <!-- <input-radio
          v-model="slotProps.data.ok"
          style="height: 100%; margin: 0px; padding: 0px;"
          :divider="false"
          :options="opcoes.okNaoOk"
        /> -->
        <div
          class="flex "
          style=""
        >
          <div class="flex align-items-center">
            <RadioButton
              v-model="slotProps.data.check"
              input-id="ok"
              name="true"
              :value="true"
            />
            <label> {{ $t('modulos.certificados.checklist.opcoes.ok') }}</label>
          </div>
          <div class="flex align-items-center">
            <RadioButton
              v-model="slotProps.data.check"
              input-id="naoOk"
              name="false"
              :value="false"
            />
            <label
              for="ingredient2"
            >{{ $t('modulos.certificados.checklist.opcoes.naoOk') }}</label>
          </div>
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        class="mr-2"
        outlined
        color="danger"
        :disabled="!podeReprovar"
        @click="() => confirmacaoReprovar()"
      >
        {{ $t('geral.botoes.reprovar') }}
      </botao-padrao>
      <botao-padrao @click="() => confirmacaoSalvar()">
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
      <Etiquetas
        ref="modal-etiquetas"
        :ordem-servico-id="id"
      />
    </div>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import OperacaoService from '@common/services/cadastros/OperacaoService';
import TabelaPadraoPrimeVue from '@components/tabelas/TabelaPadraoPrimeVue.vue';
import EmbalagensService from '@/common/services/cadastros/EmbalagensService';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import helpers from '@common/utils/helpers';
import RadioButton from 'primevue/radiobutton';
import Etiquetas from './modais/Etiquetas.vue';
import _ from 'lodash'
export default {
  components: {
    CabecalhoPagina,
    TabelaPadraoPrimeVue,
    RadioButton,
    Etiquetas
  },
  props: ['id'],
  data() {
    return {
      opcoes: {
        embalagens: [],
        okNaoOk: [
        { text: this.$t('modulos.certificados.checklist.opcoes.ok'), value: true },
        { text: this.$t('modulos.certificados.checklist.opcoes.naoOk'), value: false },
      ],
      },
      codigoOS: null,
      cliente: null,
      solicitante: null,
      dataRecebimento: null,
      dataPrevisaoEntrega: null,
      embalagemRecebimento: null,
      loading: {
        embalagensLoading: true,
      },
      form: {
        orcamentoId: this.id,
        quantidadeVolumes: null,
        peso: null,
        embalagemEntregaId: null,
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'okNaoOk',
            text: '',
          },
          {
            value: 'instrumento.codigo',
            text: this.$t('modulos.operacao.tabela.instrumento'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.operacao.tabela.tipo_instrumento'),
            sortable: true,
          },
          {
            value: 'setorAnterior.nome',
            text: this.$t('modulos.operacao.tabela.setor_anterior'),
            sortable: true,
          },
          {
            value: 'certificado',
            text: this.$t('modulos.operacao.tabela.certificado'),
            sortable: true,
          },
          {
            value: 'tipoCalibracao.nome',
            text: this.$t('modulos.operacao.tabela.tipo_calibracao'),
            sortable: true,
          },
          {
            value: 'localExecucao.nome',
            text: this.$t('modulos.operacao.tabela.local_execucao'),
            sortable: true,
          },
          {
            value: 'dataCalibracao',
            text: this.$t('modulos.operacao.tabela.data_calibracao'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            value: 'usuarioTecnicoExecutante.nome',
            text: this.$t('modulos.operacao.tabela.usuario_tecnico_executante'),
            sortable: true,
          },
          // {
          //   value: 'dataAssinatura',
          //   text: this.$t('modulos.operacao.tabela.data_assinatura'),
          //   sortable: true,
          //   formatter: (v) => {
          //     if (v) return helpers.formatarDataBr(v);
          //   },
          // },
          // {
          //   value: 'usuarioSignatario.nome',
          //   text: this.$t('modulos.operacao.tabela.usuario_signatario'),
          //   sortable: true,
          // },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
    };
  },
  computed: {
    podeReprovar() {
      return this.tabela.dados?.some((el) => !el.check);
    },
  },
  mounted() {
    this.listarRegistros();
    this.buscarEmbalagens();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.operacao.plano_inspecao')
    );
  },
  methods: {
    abreModalEtiquetas() {
      this.$refs['modal-etiquetas'].abrirModal(_.cloneDeep(this.tabela.dados));
    },
    reprovar(){
      const instrumentosNaoOK = this.tabela.dados.filter(el => !el.check)
      if(!instrumentosNaoOK?.length) return this.toastAlerta(
            this.$t(`modulos.operacao.erros.reprovar_apenas_nao_ok`)
          );
      this.$store.dispatch('Layout/iniciarCarregamento');
      OperacaoService.reprovarPlanoInspecao(this.id, { instrumentosId: instrumentosNaoOK.map(el => el.instrumento?.id) })
      .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.execucao_manutencao_peca_salvo_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err?.response?.data?.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar(){
      this.confirmar(this.$t(`modulos.operacao.deseja_continuar`), this.$t(`modulos.operacao.instrumentos_considerados_ok`))
      .then(() => this.salvar())
    },
    confirmacaoReprovar(){
      this.confirmar(this.$t(`modulos.operacao.deseja_continuar`), this.$t(`modulos.operacao.instrumentos_suplemento_devolvidos`))
        .then(() => this.reprovar())
    },
    salvar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OperacaoService.finalizarPlanoInspecao(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.plano_inspecao_salvo_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarEmbalagens() {
      return EmbalagensService.listar().then((res) => {
        this.opcoes.embalagens = new DropdownModel(res.data);
        this.loading.embalagensLoading = false;
      });
    },
    listarRegistros: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OperacaoService.buscaDadosPlanoInspecao(this.id)
        .then((res) => {
          this.codigoOS = res.data.codigoOrdemServico;
          this.cliente = res.data.participante?.nome;
          this.solicitante = res.data.solicitanteParticipante?.nome;
          this.dataRecebimento = res.data.dataRecebimento;
          this.dataPrevisaoEntrega = res.data.dataPrevisaoEntrega;
          this.embalagemRecebimento = res.data.embalagemRecebimento?.nome;
          
          res.data.instrumentos?.forEach(el => el.check = true);
          this.tabela.dados = _.cloneDeep(res.data.instrumentos)
          if (this.embalagemRecebimento)
            this.form.embalagemEntregaId = res.data.embalagemRecebimento?.id;

        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err?.response?.data?.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
  },
};
</script>
<style scoped>
:deep(.nao-permiti-executar) {
  /* background-color: rgba(0, 0, 0, 0.15) !important; */
  background-color: #f2f4f7 !important;
  color: #d0d5dd !important;
}
:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  position: fixed;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
